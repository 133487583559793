define("discourse/plugins/discourse-token-gate/discourse/initializers/extend-composer-open", ["exports", "@ember/owner", "discourse/lib/plugin-api", "discourse/plugins/discourse-token-gate/discourse/components/modal/connect-web3-wallet"], function (_exports, _owner, _pluginApi, _connectWeb3Wallet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-composer-open",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.modifyClass("service:composer", {
          pluginId: "discourse-token-gate",
          // Overriding function here first as we are not sure if this is the user flow we want. Eventually, this should
          // be added as a proper extension in core.
          open(opts) {
            const hasSufficient = !!this.currentUser?.web3_tokens?.some(token => token.has_sufficient);
            if (this.currentUser?.admin || hasSufficient) {
              return this._super(opts);
            } else {
              (0, _owner.getOwner)(this).lookup("service:modal").show(_connectWeb3Wallet.default);
            }
          }
        });

        // Ideal API we want to introduce
        // api.registerOpenComposerCallback((opts) => {
        //   if (opts.skipCallbacks) {
        //     return false;
        //   }

        //   if (currentUser.web3_has_token) {
        //     return false;
        //   } else {
        //     const modalController = showModal("connect-web3-wallet");
        //     modalController.set("composerOpenOpts", opts);
        //     return true;
        //   }
        // });
      });
    }
  };
});