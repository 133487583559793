define("discourse/plugins/discourse-token-gate/discourse/components/modal/connect-web3-wallet", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/template", "discourse/components/d-modal", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _template, _dModal, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConnectWeb3WalletModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "tokens", [_tracking.tracked], function () {
      return [];
    }))();
    #tokens = (() => (dt7948.i(this, "tokens"), void 0))();
    constructor() {
      super(...arguments);
      this.tokens = this.currentUser.web3_tokens;
    }
    get modalType() {
      if (!this.currentUser.web3_public_key) {
        return "no_connected_wallet";
      }
      const hasSufficient = !!this.currentUser?.web3_tokens?.some(token => token.has_sufficient);
      if (!hasSufficient) {
        return "no_token";
      }
    }
    get modalTitle() {
      return _I18n.default.t(`web3.modal.${this.modalType}.title`);
    }
    get modalBodyText() {
      const groupedData = this.#tokensGroupedByNameAndBalance();
      const messages = Object.values(groupedData).map(data => {
        return _I18n.default.t(`web3.modal.${this.modalType || "no_token"}.description`, {
          href: `/u/${this.currentUser.username}/preferences/wallets`,
          requiredMinTokenBalance: data.requiredBalance,
          tokenName: data.tokenName,
          tokenBlockchain: data.tokenBlockchain
        });
      });
      return (0, _template.htmlSafe)(messages.join("<hr>"));
    }
    #tokensGroupedByNameAndBalance() {
      const groupedData = {};
      const tokens = JSON.parse(this.siteSettings.web3_tokens || "[]");
      tokens.forEach(t => {
        const tokenName = t.token_name;
        const requiredBalance = t.required_token_balance;
        const key = `${tokenName}:${requiredBalance}`;
        let tokenBlockchain = t.blockchain;
        if (groupedData[key]) {
          tokenBlockchain = `${groupedData[key].tokenBlockchain}, ${tokenBlockchain}`;
        }
        groupedData[key] = {
          tokenName,
          requiredBalance,
          tokenBlockchain
        };
      });
      return groupedData;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{this.modalTitle}}
          @closeModal={{@closeModal}}
          class="connect-web3-wallet-modal"
        >
          <p>{{htmlSafe this.modalBodyText}}</p>
        </DModal>
      
    */
    {
      "id": "vBVMSGCx",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"connect-web3-wallet-modal\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"modalTitle\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[10,2],[12],[1,[28,[32,1],[[30,0,[\"modalBodyText\"]]],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-token-gate/discourse/components/modal/connect-web3-wallet.js",
      "scope": () => [_dModal.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ConnectWeb3WalletModal;
});