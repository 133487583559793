define("discourse/plugins/discourse-token-gate/discourse/services/wallet-connect", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax", "discourse/lib/load-script", "I18n"], function (_exports, _tracking, _service, _ajax, _loadScript, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WalletConnectService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_tracking.tracked], function () {
      return null;
    }))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    metadata = {
      name: "Ape",
      description: "AppKit Example",
      url: "https://local.vanguardheroesgame.com",
      // origin must match your domain & subdomain
      icons: ["https://assets.reown.com/reown-profile-pic.png"]
    };
    async connect(chainId) {
      await this._loadScripts();
      const {
        EthersAdapter
      } = window.AppKitBundle.EthersAdapter;
      const {
        createAppKit
      } = window.AppKitBundle.AppKit;
      // 1. Get projectId from https://cloud.reown.com
      const projectId = "be46f12df65cf3498f552ebcf5855393";
      const metadata = {
        name: "Discourse",
        description: "Connect to your Discourse account",
        url: `https://${window.location.host.split(':')[0]}`,
        // Only domain and subdomain
        icons: ["https://assets.reown.com/reown-profile-pic.png"]
      };
      const networks = JSON.parse(this.siteSettings.web3_tokens).flatMap(token => {
        return Object.values(window.AppKitBundle.networks).filter(network => network.id === Number(token.chain_id));
      });
      if (!this.modal) {
        this.modal = createAppKit({
          adapters: [new EthersAdapter()],
          networks,
          metadata,
          projectId,
          features: {
            analytics: false
          }
        });
        const myModal = this.modal;
        this.modal.subscribeWalletInfo(async function () {
          const {
            message
          } = await (0, _ajax.ajax)("/web3/signing_message", {
            method: "PUT"
          });
          await myModal.connectionControllerClient.signMessage(message).then(async signature => {
            await (0, _ajax.ajax)("/web3/verify_public_key", {
              type: "PUT",
              data: {
                signature,
                public_key: myModal.getAddress(),
                chain_id: myModal.getChainId()
              }
            });
            window.location.reload();
          });
        });
      }
      this.modal.switchNetwork(networks.find(network => network.id === chainId));
      setTimeout(() => {
        this.modal.open();
      }, 400);
    }
    async verify(account, chainId) {
      try {
        const config = this._getTokenConfig(chainId);

        // Ensure the chain is set up properly
        await this.ensureChain(config);
        const {
          message
        } = await (0, _ajax.ajax)("/web3/signing_message", {
          method: "PUT"
        });
        const signature = await this.connector.signPersonalMessage([message, account]);
        const result = await (0, _ajax.ajax)("/web3/verify_public_key", {
          type: "PUT",
          data: {
            signature,
            public_key: account,
            chain_id: chainId
          }
        });

        // Update the current user's web3 information
        this.currentUser.setProperties({
          web3_public_key: result.public_key,
          web3_tokens: result.web3_tokens
        });
        window.location.reload();
      } catch (error) {
        this.dialog.alert(_I18n.default.t("web3.verification_failed", {
          error: error.message
        }));
        throw error;
      }
    }
    async ensureChain(config) {
      try {
        if (!this.connector.connected) {
          throw new Error(_I18n.default.t("web3.wallet_connect.not_connected"));
        }
        const sessionChainId = this.connector.chainId;
        if (sessionChainId !== config.chain_id) {
          throw new Error(_I18n.default.t("web3.wallet_connect.chain_mismatch", {
            required: config.chain_id,
            actual: sessionChainId
          }));
        }
      } catch (error) {
        this.dialog.alert(error.message);
        throw error;
      }
    }
    _getTokenConfig(chainId) {
      const tokens = JSON.parse(this.siteSettings.web3_tokens || "[]");
      const config = tokens.find(token => token.chain_id === chainId);
      if (!config) {
        throw new Error(_I18n.default.t("web3.wallet_connect.chain_not_supported", {
          chainId
        }));
      }
      return config;
    }
    async _loadScripts() {
      await (0, _loadScript.default)("/plugins/discourse-token-gate/appkit.umd.min.js", {
        css: false
      });
    }
  }
  _exports.default = WalletConnectService;
});