define("discourse/plugins/discourse-token-gate/discourse/initializers/setup-message-bus", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-token-gate-setup-message-bus",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        const messageBus = api.container.lookup("service:message-bus");
        const currentUser = api.getCurrentUser();
        if (currentUser) {
          messageBus.subscribe("/discourse-token-gate/user", data => {
            currentUser.set("web3_tokens", data.web3_tokens);
          });
        }
      });
    }
  };
});