define("discourse/plugins/discourse-token-gate/discourse/routes/preferences-wallets", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/routes/restricted-user"], function (_exports, _service, _ajax, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesWallets extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async model() {
      const userId = this.modelFor("user").id;
      const siteTokens = JSON.parse(this.siteSettings.web3_tokens || "[]");
      let userTokens;
      let publicKey;
      if (userId === this.currentUser.id) {
        userTokens = this.currentUser.web3_tokens || [];
        publicKey = this.currentUser.web3_public_key;
      } else {
        const response = await (0, _ajax.ajax)(`/web3/user_public_key/${userId}`);
        userTokens = response.web3_tokens || [];
        publicKey = response.public_key;
      }
      return {
        publicKey,
        web3_tokens: this.enrichUserTokens(userTokens, siteTokens)
      };
    }
    enrichUserTokens(userTokens, siteTokens) {
      const tokensByNameAndBlockchain = Object.fromEntries(siteTokens.map(token => [`${token.token_name}:${token.blockchain}`, token.required_token_balance]));
      return userTokens.map(userToken => ({
        ...userToken,
        required_token_balance: tokensByNameAndBlockchain[`${userToken.token_name}:${userToken.blockchain}`] || null
      }));
    }
  }
  _exports.default = PreferencesWallets;
});